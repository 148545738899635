import React, { FC } from 'react';
import { Tooltip } from 'react-leaflet';

import styles from '../styles';
import { defaultOffset } from '.';
import { HoveringDottPoint,HoverTooltipProps } from './types';

const DotAddInfoTooltip: FC<HoverTooltipProps> = ({ hoveringElement }) => {
  const classes = styles();
  const { offset, number, coords } = hoveringElement as HoveringDottPoint;

  return (
    <Tooltip direction="top" offset={offset || defaultOffset}>
      <p className={classes.tooltipHeader}>{number}</p>
      <p>{coords}</p>
    </Tooltip>
  );
};

export default DotAddInfoTooltip;
