import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DatePeriodEnum } from '_store/utils';
import {
  CreateIVIMPayload,
  DisableIVIMPayload,
  GetIVIMListPaylaod,
  UpdateIVIMPayload,
} from '../../lib/api';
import { Ivim, IvimDateFilterType, IvimReducerState, IvimStatusFilter } from './ivimsTypes';

const initialState: IvimReducerState = {
  loading: false,
  statusFilter: IvimStatusFilter.IN_PROGRESS,
  dateFilter: DatePeriodEnum.lastDay,
  createdFromFilter: null,
  createdToFilter: null,
  dateFilterType: IvimDateFilterType.RELATIVE,
  roadSegmentId: null,
  total: 0,
  paginationCurrentPage: 1,
  serviceCategoryCodeFilter: null,
  serviceSubCategoryCodeFilter: null,
};

export const ivimsAdapter = createEntityAdapter({
  selectId: (el: Ivim) => el.id,
});

const ivimsReducer = createSlice({
  name: 'ivims',
  initialState: ivimsAdapter.getInitialState(initialState),
  reducers: {
    ivimsRequest: (state, _action: PayloadAction<GetIVIMListPaylaod>) => {
      const {
        date,
        createdFrom,
        createdTo,
        status,
        currentPage,
        serviceCategoryCode,
        serviceSubCategoryCode,
      } = _action.payload;

      state.loading = true;

      if (date) {
        state.dateFilter = date;
      }
      if (createdFrom) {
        state.createdFromFilter = createdFrom;
      }
      if (createdTo) {
        state.createdToFilter = createdTo;
      }
      if (currentPage) {
        state.paginationCurrentPage = currentPage;
      }
      if (serviceCategoryCode) {
        state.serviceCategoryCodeFilter = serviceCategoryCode;
      }
      if (serviceSubCategoryCode) {
        state.serviceSubCategoryCodeFilter = serviceSubCategoryCode;
      }

      state.statusFilter = status !== undefined ? status : state.statusFilter;
    },
    ivimsResponse: (state, action) => {
      if (state.loading) {
        ivimsAdapter.setAll(state, action.payload.results);
        state.total = action.payload.total;
        state.loading = false;
      }
    },
    ivimsAddOne: ivimsAdapter.addOne,
    ivimsAddMany: ivimsAdapter.addMany,
    ivimUpdate: ivimsAdapter.updateOne,

    ivimRemove: ivimsAdapter.removeOne,
    ivimRemoveMany: ivimsAdapter.removeMany,

    ivimUpsertOne: ivimsAdapter.upsertOne,
    ivimUpsertMany: ivimsAdapter.upsertMany,

    createIVIM: (state, _action: PayloadAction<CreateIVIMPayload>) => {
      state.loading = true;
    },
    updateIVIM: (state, _action: PayloadAction<UpdateIVIMPayload>) => {
      state.loading = true;
    },
    deleteIVIM: (state, _action: PayloadAction<string>) => {
      state.loading = true;
    },
    resetLoading: (state) => {
      state.loading = false;
    },
    deleteIVIMs: (state, _action: PayloadAction<string[]>) => {
      state.loading = true;
    },
    disableIVIM: (state, _action: PayloadAction<DisableIVIMPayload>) => {
      state.loading = true;
    },
    changeDateFilterType: (state, _action: PayloadAction<IvimDateFilterType>) => {
      state.dateFilterType = _action.payload;
    },
  },
});

export default ivimsReducer;
